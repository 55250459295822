import appHeader from "@shared/app-header"
import appSidebar from "@/components/app-sidebar"
import alert from "@/components/alert-box"
import pagination from "@/components/pagination"
import { mapActions, mapGetters } from "vuex";
import axios from "@/axios"

export default {
  name: 'dashboard',
  components: {
    appHeader,
    appSidebar,
    alert,
    pagination,
  },
  props: [],
  data () {
    return {
      notificationCount: 0,
    }
  },
  computed: {
    ...mapGetters(["getThemeColors","getDefaultColors","getClientId"]),
    themeColors() {
      const colors = this.getThemeColors;
      return Object.values(colors).reduce((result, color) => {
        if (color.value) result[color.var] = color.value;
        return result;
      }, {})
    },
  },
  async created () {
    await this.fetchNewClientId()
    await this.fetchUIConf(this.getClientId)
  },
  methods: {
    ...mapActions(["fetchUIConf"]),
    async fetchNewClientId() {
      if (!this.$store.getters.getClientId) {
        await this.$store.dispatch("fetchClientId");
        // this.tenant_id = this.$store.getters.getTenantId;
      }
      await this.fetchDashboardNotification()
      // try {
      //   const { data } = await axios.post(`user-auth/init`)
      //   if(data){
      //     console.log('data.tenant_id :>> ', data.tenant_id);
      //     this.$store.dispatch("setTenantId", data.tenant_id)
          // if (data.effective_role === 'tenant_super_admin') this.$router.push({path: '/field-table'})
      //   }
        
      // } catch (error) {
      //   console.log(error.message || `Error fetching tenant ID `);        
      // }
    },
    async fetchDashboardNotification(){
      try{
        const { data } = await axios.get(`dashboard-notifications/client/count`)
        this.notificationCount = data.data
      }
      catch(error){
        console.log(">>>> error", error)
      }
    }

  }
}


