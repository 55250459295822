import { render, staticRenderFns } from "./dashboard.html?vue&type=template&id=2f93bfbd&scoped=true&external"
import script from "./dashboard.js?vue&type=script&lang=js&external"
export * from "./dashboard.js?vue&type=script&lang=js&external"
import style0 from "./dashboard.scss?vue&type=style&index=0&id=2f93bfbd&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f93bfbd",
  null
  
)

export default component.exports